<template>
  <div class="aboutMind">
    <div class="aboutTop">
      <el-carousel class="aboutTop" indicator-position="outside">
        <el-carousel-item class="aboutTop" v-for="i in imgList" :key="i.id">
          <img :src="i.url" class="aboutTopImg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="aboutBody">
      <img v-for="s in bodyImg" :key="s.id" :src="s.url" class="aboutBodyImg">
    </div>
    <!-- <div class=""></div> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      imgList: [
        {
          id: 0,
          url: "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112150133_组_78.png",
        },
      ],
      bodyImg: [
        {id:0,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112150406_组_1445.png'},
        {id:1,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112150447_组_1446.png'},
        {id:2,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112150532_组_1447.png'},
        {id:3,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112151020_1.png'}
      ]
    }
  }
}
</script>

<style>
.aboutMind{
  width: 100%;
  height: 100%;
}

.aboutTop{
  width: 100%;
  height: 800px;
}

.el-carousel__container {
  width: 100%;
  height: 760px;
}

.aboutTopImg {
  width: 100%;
  height: 100%;
}

.aboutBody{
  width: 100%;
  min-height: 200px;
  display: block;
}

.aboutBodyImg{
  width: 100%;
  display: block;
}
</style>